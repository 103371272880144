<template>
  <div class="menu">
    <a-menu
      :selectedKeys="selectedKeys"
      :openKeys="openKeys"
      theme="dark"
      mode="inline"
    >
      <a-menu-item key="1">
        <HomeOutlined />
        <span class="nav-text"><router-link to="/">首页</router-link></span>
      </a-menu-item>
      <a-menu-item key="12">
        <ShopOutlined />
        <span class="nav-text"><router-link to="/workshop">车间门店</router-link></span>
      </a-menu-item>
      <a-menu-item key="18">
        <ShopOutlined />
        <span class="nav-text"><router-link to="/introducer">引荐达人</router-link></span>
      </a-menu-item>
      <a-menu-item key="11">
        <BookOutlined />
        <span class="nav-text"><router-link to="/banner">Banner</router-link></span>
      </a-menu-item>
      <a-menu-item key="3">
        <CarOutlined />
        <span class="nav-text"><router-link to="/vehicle-brand">车型</router-link></span>
      </a-menu-item>
      <a-menu-item key="2">
        <BarsOutlined />
        <span class="nav-text"><router-link to="/catalogues">目录</router-link></span>
      </a-menu-item>
      <a-menu-item key="4">
        <TrademarkOutlined />
        <span class="nav-text"><router-link to="/license-brand">授权品牌</router-link></span>
      </a-menu-item>
      <a-menu-item key="5">
        <ShoppingOutlined />
        <span class="nav-text"><router-link to="/products">车间产品</router-link></span>
      </a-menu-item>
      <a-menu-item key="7">
        <ProjectOutlined />
        <span class="nav-text"><router-link to="/films">改色膜颜色</router-link></span>
      </a-menu-item>
      <a-menu-item key="17">
        <ProjectOutlined />
        <span class="nav-text"><router-link to="/wheel">轮毂</router-link></span>
      </a-menu-item>
      <a-menu-item key="6">
        <InboxOutlined />
        <span class="nav-text"><router-link to="/film-category">改色膜类型</router-link></span>
      </a-menu-item>
      <a-menu-item key="8">
        <FileImageOutlined />
        <span class="nav-text"><router-link to="/casebooks">产品案例</router-link></span>
      </a-menu-item>
      <a-menu-item key="9">
        <BookOutlined />
        <span class="nav-text"><router-link to="/articles">首页文章</router-link></span>
      </a-menu-item>
      <a-menu-item key="19">
        <BookOutlined />
        <span class="nav-text"><router-link to="/coupons">优惠券活动</router-link></span>
      </a-menu-item>
      <a-menu-item key="13">
        <MessageOutlined />
        <span class="nav-text"><router-link to="/word-skill">话术库</router-link></span>
      </a-menu-item>
      <a-menu-item key="14">
        <MessageOutlined />
        <span class="nav-text"><router-link to="/price-table">报价单</router-link></span>
      </a-menu-item>
      <a-menu-item key="16">
        <UserOutlined />
        <span class="nav-text"><router-link to="/manager-casebooks">车间服务经理案例</router-link></span>
      </a-menu-item>
      <a-menu-item key="15">
        <UserOutlined />
        <span class="nav-text"><router-link to="/users">账号管理</router-link></span>
      </a-menu-item>
      <!-- <a-sub-menu key="s1">
        <template #icon><MessageOutlined /></template>
        <template #title>nav 2</template>
        <a-menu-item key="2"><router-link to="/">1</router-link></a-menu-item>
        <a-menu-item key="3"><router-link to="/about">webkit</router-link></a-menu-item>
      </a-sub-menu> -->
    </a-menu>
  </div>
</template>

<script>
// import { ref } from "vue";
export default {
  name: "SiderMenu",
  props: ['selectedKeys', 'openKeys'],
  setup() {
    return {
    };
  },
};
</script>

<style lang="scss">
.ant-menu-item {
  a {
    color: #fff !important;
  }
}
</style>
