import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layout/Index.vue'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {
      menuKey: [['1'], ['']],
      breadcrumb: ['超级车间']
    },
    children: [
      {
        path: '/catalogues',
        name: 'Catalogues',
        component: () => import('../views/Catalogues.vue'),
        meta: {
          menuKey: [['2'], ['']],
          breadcrumb: ['超级车间', '目录']
        }
      }, {
        path: '/vehicle-brand',
        name: 'VehicleBrand',
        component: () => import('../views/VehicleBrand.vue'),
        meta: {
          menuKey: [['3'], ['']],
          breadcrumb: ['超级车间', '车型']
        }
      }, {
        path: '/workshop',
        name: 'Workshop',
        component: () => import('../views/Workshop.vue'),
        meta: {
          menuKey: [['12'], ['']],
          breadcrumb: ['超级车间', '车间门店']
        }
      }, {
        path: '/license-brand',
        name: 'LicenseBrand',
        component: () => import('../views/LicenseBrand.vue'),
        meta: {
          menuKey: [['4'], ['']],
          breadcrumb: ['超级车间', '授权品牌']
        }
      }, {
        path: '/products',
        name: 'Products',
        component: () => import('../views/Products.vue'),
        meta: {
          menuKey: [['5'], ['']],
          breadcrumb: ['超级车间', '车间产品']
        }
      }, {
        path: '/film-category',
        name: 'FilmCategory',
        component: () => import('../views/FilmCategory.vue'),
        meta: {
          menuKey: [['6'], ['']],
          breadcrumb: ['超级车间', '改色膜类型']
        }
      }, {
        path: '/films',
        name: 'VehicleFilms',
        component: () => import('../views/VehicleFilms.vue'),
        meta: {
          menuKey: [['7'], ['']],
          breadcrumb: ['超级车间', '改色膜列表']
        }
      }, {
        path: '/wheel',
        name: 'VehicleWheel',
        component: () => import('../views/VehicleWheel.vue'),
        meta: {
          menuKey: [['17'], ['']],
          breadcrumb: ['超级车间', '轮毂列表']
        }
      }, {
        path: '/casebooks',
        name: 'Casebooks',
        component: () => import('../views/Casebooks.vue'),
        meta: {
          menuKey: [['8'], ['']],
          breadcrumb: ['超级车间', '产品案例']
        }
      }, {
        path: '/articles',
        name: 'Articles',
        component: () => import('../views/Articles.vue'),
        meta: {
          menuKey: [['9'], ['']],
          breadcrumb: ['超级车间', '首页文章']
        }
      }, {
        path: '/coupons',
        name: 'Coupons',
        component: () => import('../views/Coupons.vue'),
        meta: {
          menuKey: [['19'], ['']],
          breadcrumb: ['超级车间', '优惠券活动']
        }
      }, {
        path: '/edit-article',
        name: 'EditArticle',
        component: () => import('../views/EditArticle.vue'),
        meta: {
          menuKey: [['10'], ['']],
          breadcrumb: ['超级车间', '编辑文章']
        }
      }, {
        path: '/banner',
        name: 'Banner',
        component: () => import('../views/Banner.vue'),
        meta: {
          menuKey: [['11'], ['']],
          breadcrumb: ['超级车间', 'banner']
        }
      }, {
        path: '/word-skill',
        name: 'WordSkill',
        component: () => import('../views/WordSkill.vue'),
        meta: {
          menuKey: [['13'], ['']],
          breadcrumb: ['超级车间', '话术库']
        }
      }, {
        path: '/edit-word-skill',
        name: 'EditWordSkill',
        component: () => import('../views/EditWordSkill.vue'),
        meta: {
          breadcrumb: ['超级车间', '话术库']
        }
      }, {
        path: '/price-table',
        name: 'PriceTable',
        component: () => import('../views/PriceTable.vue'),
        meta: {
          menuKey: [['14'], ['']],
          breadcrumb: ['超级车间', '报价单']
        }
      }, {
        path: '/edit-price-table',
        name: 'EditPriceTable',
        component: () => import('../views/EditPriceTable.vue'),
        meta: {
          breadcrumb: ['超级车间', '报价单']
        }
      }, {
        path: '/users',
        name: 'Users',
        component: () => import('../views/Users.vue'),
        meta: {
          menuKey: [['15'], ['']],
          breadcrumb: ['超级车间', '账号管理']
        }
      }, {
        path: '/manager-casebooks',
        name: 'GigaManagerCasebooks',
        component: () => import('../views/GigaManagerCasebooks.vue'),
        meta: {
          menuKey: [['16'], ['']],
          breadcrumb: ['超级车间', '服务经理案例']
        }
      }, {
        path: '/introducer',
        name: 'Introducer',
        component: () => import('../views/Introducer.vue'),
        meta: {
          menuKey: [['18'], ['']],
          breadcrumb: ['超级车间', '引荐达人']
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
